import {createApp} from 'vue'
import {createInertiaApp} from '@inertiajs/vue3'
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers'
import {createPinia} from "pinia"
import {rootApp} from "./bootstrap"
import vReveal from './directives/vReveal'
import vTranslate from './directives/vTranslate'
import InstantSearch from 'vue-instantsearch/vue3/es'
import VueGtag from 'vue-gtag'

const appName: string = 'Renault'

createInertiaApp({
    title: (title) => title ? `${title} - ${appName}` : appName,
    resolve: (name) => <any>resolvePageComponent(`./pages/${name}.vue`, import.meta.glob('./pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const app = createApp(rootApp(App,props))
        app.use(plugin)
        app.use(createPinia())
        app.directive('reveal', vReveal)
        app.directive('translate', vTranslate)
        app.use(InstantSearch)
        app.use(VueGtag, {
            config: {id: 'AW-996702236'}
        })
        app.mount(el)
    },
    progress: {
        color: '#4B5563'
    }
})
